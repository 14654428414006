



























































































import { Row } from "@/hooks";
import {
  OptionModel,
  Pagination,
} from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { salesOrderServices } from "@/services/salesorder.service";
import { FormModel } from "ant-design-vue";
import moment from "moment";
import { Component, Ref, Vue } from "vue-property-decorator";
import { UnitPositionService } from "./services";
import {
  IReportDailyOperationalDeliveryDTO,
  IReportDailyOperationalExchange,
  IReportDailyOperationalWithdrawDTO,
} from "./types";

enum POSITION_UNIT_TYPE {
  UNIT_DELIVERY = "UNIT_DELIVERY",
  UNIT_EXCHANGE = "UNIT_EXCHANGE",
  UNIT_WITHDRAWAL = "UNIT_WITHDRAWAL",
}

const UnitDelivery = () =>
  import(/* webpackPrefetch: true */ "./UnitDelivery.vue");
const UnitExchange = () =>
  import(/* webpackPrefetch: true */ "./UnitExchange.vue");
const UnitWithdrawal = () =>
  import(/* webpackPrefetch: true */ "./UnitWithdrawal.vue");

export interface FilterForm {
  branch: string;
  type: string;
  date?: string | null;
  customerName: string;
}

@Component({
  components: {
    "unit-withdrawal": UnitWithdrawal,
    "unit-exchange": UnitExchange,
    "unit-delivery": UnitDelivery,
  },
})
export default class UnitPositionReport extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  @Ref("form") form!: FormModel;

  unitPositionService = new UnitPositionService();
  filterForm: FilterForm = {
    branch: "",
    type: "",
    date: null,
    customerName: "",
  };
  dataSourceUnitDelivery = {} as Pagination<IReportDailyOperationalDeliveryDTO>;
  dataSourceUnitExchange: Row<IReportDailyOperationalExchange, string>[] = [];
  dataSourceUnitWithdrawal =
    {} as Pagination<IReportDailyOperationalWithdrawDTO>;
  formRules = {
    branch: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    date: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
  };
  unitPositionTypeOptions: OptionModel[] = [
    {
      label: "lbl_all",
      value: "",
    },
  ];
  selectedTabs: OptionModel[] = [];
  dataBranch: DataWarehouseBranch[] = [];
  currentTabComponent = POSITION_UNIT_TYPE.UNIT_DELIVERY.replace(
    /_/gi,
    "-"
  ).toLowerCase();
  loading = {
    download: false,
    unitDelivery: false,
    unitExchange: false,
    unitWithdraw: false,
  };
  // trigger reset on each table page
  statePage = {
    withdraw: 0,
    exchange: 0,
    delivery: 0,
  };

  created(): void {
    for (const key in POSITION_UNIT_TYPE) {
      if (Object.prototype.hasOwnProperty.call(POSITION_UNIT_TYPE, key)) {
        this.unitPositionTypeOptions.push({
          label: `lbl_${POSITION_UNIT_TYPE[key].toLowerCase()}`,
          value: POSITION_UNIT_TYPE[key],
        });
      }
    }
    this.selectedTabs = this.unitPositionTypeOptions.filter(
      t => t.value !== ""
    );
  }

  handleListBranch(data) {
    this.dataBranch = data;
  }

  getReportDailyDelivery(
    props: RequestQueryParamsModel = { page: 0, limit: 10 }
  ): void {
    const params: RequestQueryParamsModel = {
      ...props,
    };
    if (params.page === 0) this.statePage.delivery = 1;
    this.loading.unitDelivery = true;
    this.unitPositionService
      .getListReportDailyOperationalDelivery(params)
      .then(res => (this.dataSourceUnitDelivery = res))
      .finally(() => {
        this.loading.unitDelivery = false;
        this.statePage.delivery = 0;
      });
  }

  getReportDailyExchange(props: RequestQueryParamsModel = {}): void {
    this.loading.unitExchange = true;
    this.unitPositionService
      .getListReportDailyOperationalExchange(props)
      .then(res => {
        this.dataSourceUnitExchange = res.map((item, i) => ({
          ...item,
          key: item.id,
          no: i,
        }));
      })
      .finally(() => {
        this.loading.unitExchange = false;
        this.statePage.exchange = 0;
      });
  }

  getReportDailyWithdraw(
    props: RequestQueryParamsModel = { page: 0, limit: 10 }
  ): void {
    const params: RequestQueryParamsModel = {
      ...props,
    };
    if (params.page === 0) this.statePage.withdraw = 1;
    this.loading.unitWithdraw = true;
    this.unitPositionService
      .getListReportDailyOperationalWithdraw(params)
      .then(res => {
        this.dataSourceUnitWithdrawal = res;
      })
      .finally(() => {
        this.loading.unitWithdraw = false;
        this.statePage.withdraw = 0;
      });
  }

  assignSearchUnitDelivery(key, value, and): string {
    if (key === "branch" && value)
      return and + `location.warehouse.branchWarehouse.secureId~${value}`;
    else if (key === "date" && value)
      return (
        and +
        `deliveryOrder.date>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}_AND_deliveryOrder.date<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "customerName" && value)
      return and + `deliveryOrder.customer.secureId~${value}`;
    else return "";
  }

  assignSearchUnitExchange(key, value, and): string {
    if (key === "branch" && value) return and + `branchId~${value}`;
    else if (key === "date" && value)
      return (
        and +
        `replacementDate>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}_AND_replacementDate<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "customerName" && value)
      return and + `customerId~${value}`;
    else return "";
  }

  assignSearchUnitWithdrawal(key, value, and): string {
    if (key === "branch" && value)
      return and + `internalContract.branch.secureId~${value}`;
    else if (key === "date" && value)
      return (
        and +
        `withdrawalDate>=${moment(value)
          .set({ hour: 0, minute: 0, second: 0 })
          .utcOffset("+07")
          .format()}_AND_withdrawalDate<=${moment(value)
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else if (key === "customerName" && value)
      return and + `internalContract.customer.secureId~${value}`;
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        switch (this.currentTabComponent) {
          case "unit-delivery":
            search = this.assignSearchUnitDelivery(key, res[key], "");
            break;
          case "unit-exchange":
            search = this.assignSearchUnitExchange(key, res[key], "");
            break;
          case "unit-withdrawal":
            search = this.assignSearchUnitWithdrawal(key, res[key], "");
            break;
          default:
            break;
        }
      } else {
        switch (this.currentTabComponent) {
          case "unit-delivery":
            search += this.assignSearchUnitDelivery(key, res[key], "_AND_");
            break;
          case "unit-exchange":
            search += this.assignSearchUnitExchange(key, res[key], "_AND_");
            break;
          case "unit-withdrawal":
            search += this.assignSearchUnitWithdrawal(key, res[key], "_AND_");
            break;
          default:
            break;
        }
      }
    });
    return search;
  }

  handleSubmit(props: RequestQueryParamsModel = { page: 0, limit: 10 }): void {
    this.form.validate(valid => {
      if (valid) {
        props.search = this.dynamicSearch(this.filterForm);
        switch (this.currentTabComponent) {
          case "unit-delivery":
            this.getReportDailyDelivery(props);
            break;
          case "unit-exchange":
            if (props.search) {
              props.search += `_AND_bastNumberNew!null`;
            } else {
              props.search = `bastNumberNew!null`;
            }
            this.getReportDailyExchange(props);
            break;
          case "unit-withdrawal":
            if (props.search) {
              props.search += `_AND_withdrawlNumber!null`;
            } else {
              props.search = `withdrawlNumber!null`;
            }
            this.getReportDailyWithdraw(props);
            break;
          default:
            break;
        }
      }
    });
  }

  resetForm(): void {
    this.form.resetFields();
    this.filterForm.customerName = "";
  }

  changeTab(key: string): void {
    this.currentTabComponent = `${key.replace(/_/gi, "-").toLowerCase()}`;
    this.handleSubmit();
  }

  checkValue(value): string {
    if (value && !value.includes("undefined") && value !== ",") {
      return value;
    } else {
      return ",ALL";
    }
  }

  checkParams(res): string {
    let params = "";
    const branchName = this.dataBranch.find(
      dataFind => dataFind.id === res["branch"]
    )?.name;
    // company is mandatory
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(`,${branchName}`);
    params += this.checkValue(
      res["date"] ? `,${moment(res["date"]).format("DD-MMM-yyyy")}` : null
    );
    return params;
  }

  queryParamDownload(res): RequestQueryParamsModel {
    return {
      branch: res["branch"],
      dateFrom: res["date"]
        ? moment(res["date"])
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()
        : "",
      dateTo: res["date"]
        ? moment(res["date"])
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()
        : "",
      customer: res["customerName"],
    };
  }

  downloadReport(): void {
    this.form.validate(valid => {
      if (valid) {
        const params: RequestQueryParamsModel = {};
        params.params = this.checkParams(this.filterForm);
        this.loading.download = true;
        salesOrderServices
          .downloadReportDailyOperational({
            ...params,
            ...this.queryParamDownload(this.filterForm),
          })
          .then(response => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "reports_daily_operational.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loading.download = false));
      }
    });
  }
}
