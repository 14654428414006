import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/HttpClient.service";
import { AxiosError } from "axios";

import {
  IReportDailyOperationalDeliveryDTO,
  IReportDailyOperationalExchange,
  IReportDailyOperationalWithdrawDTO,
} from "../types";

class UnitPositionService extends HttpClient {
  getListReportDailyOperationalDelivery = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<IReportDailyOperationalDeliveryDTO>> => {
    return this.get<Pagination<IReportDailyOperationalDeliveryDTO>>(
      Api.ReportDailyOperational + "/delivery",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListReportDailyOperationalExchange = (
    params: RequestQueryParamsModel
  ): Promise<IReportDailyOperationalExchange[]> => {
    return this.get<IReportDailyOperationalExchange[]>(
      Api.ReportDailyOperational + "/exchange",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListReportDailyOperationalWithdraw = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<IReportDailyOperationalWithdrawDTO>> => {
    return this.get<Pagination<IReportDailyOperationalWithdrawDTO>>(
      Api.ReportDailyOperational + "/withdrawl",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  downloadReportDailyOperational = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.ReportDailyOperational + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}

export default UnitPositionService;
